import Booking from '@/models/Booking'
import Api from '@/services/api'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    list: [],
    listMeta: {
      current_page: 0,
      last_page: 1,
    },
    bookingDetails: null,
    totalBookings: 0,
    upcomingBookings: [],
    totalUpcomingBookings: 0,
    pendingBookings: [],
    totalBookingsPending: 0,
    queryParams: null,
    scrollPosition: 0,
  },

  mutations: {
    setBookingList(state, bookings) {
      state.list = bookings
      state.totalBookings = 0
    },

    clearBookingList(state) {
      state.list = []
      state.upcomingBookings = []
      state.pendingBookings = []
    },

    setBookingListMeta(state, meta) {
      state.listMeta = meta
    },

    setBookingDetails(state, booking) {
      if (state.bookingDetails) {
        if (!booking.user) booking.user = state.bookingDetails.user
        if (!booking.building) booking.building = state.bookingDetails.building
        if (!booking.bookable) booking.bookable = state.bookingDetails.bookable
      }

      if (booking instanceof Booking) {
        state.bookingDetails = booking
      } else {
        state.bookingDetails = new Booking(booking)
      }
    },

    setSelectedBookingStatus(state, payload) {
      if (state.bookingDetails) {
        state.bookingDetails.status = payload
      }
    },

    clearBookingDetails(state) {
      state.bookingDetails = null
    },

    setTotalBookings(state, total) {
      state.totalBookings = total
    },

    setBookingsUpcoming(state, bookings) {
      state.upcomingBookings = bookings
    },

    setTotalBookingsUpcoming(state, total) {
      state.totalUpcomingBookings = total
    },

    setBookingsPending(state, bookings) {
      state.pendingBookings = bookings
    },

    setTotalBookingsPending(state, total) {
      state.totalBookingsPending = total
    },

    setQueryParams(state, params) {
      state.queryParams = params
    },

    setScrollPosition(state, scrollPosition) {
      state.scrollPosition = scrollPosition
    },
  },

  actions: {
    async getAvailableTimeSlots({ commit }, formData) {
      const { data } = await Api.post(`bookings/available-time-slots`, formData)
      return data.data
    },

    async createBooking({ commit }, formData) {
      await Api.post(`bookings`, formData)
    },

    async getBookings({ commit, rootGetters }, params) {
      const query = Booking.page(params.page || 1).orderBy(params.sort)

      if (params.filter) {
        if (params.filter === 'deleted') {
          query.where('trashed', 'only')
        } else {
          query.where('status', params.filter)
        }
      } else {
        query.where('trashed', 'with')
      }

      if (params.search) {
        query.where('search', encodeURIComponent(params.search))
      }

      if (params.bookable_id) {
        query.where('bookable_id', params.bookable_id)
      }

      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        query.where('building_id', filteredBuilding)
      }

      query.include(['user', 'building', 'bookable', 'user.property'])

      const res = await query.params({ limit: params.itemsPerPage || 10 }).get()

      commit('setBookingList', res.data)
      commit('setBookingListMeta', res.meta)
      if (get(res, 'meta.total', 0) > 0) {
        commit('setTotalBookings', get(res, 'meta.total', 0))
      }
    },

    async getBookingDetails({ commit }, id) {
      const { data } = await Api.get(
        `bookings/${id}?include=user,building,bookable,user.property`
      )
      commit('setBookingDetails', data.data)
    },

    async getUpcomingBooking({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        page: params.page || 1,
        sort: params.sort || 'bookable',
        include: ['user', 'building', 'bookable.media', 'user.property'],
        limit: params.itemsPerPage || 5,
      })

      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      if (filteredBuilding) {
        queryParams.append('filter[building_id]=', filteredBuilding)
      }

      const url = `bookings/bookingUpcoming?${queryParams.toString()}`

      const { data } = await Api.get(url)

      const pendingBookingsData = data.data.map((booking) => {
        const date = new Date(booking.datetime_start)
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`
        const hours = date.getHours() % 12 || 12 // Convert 0 to 12-hour format
        const period = date.getHours() >= 12 ? 'PM' : 'AM'
        const formattedTime = `${hours}:${('0' + date.getMinutes()).slice(
          -2
        )} ${period}`
        return {
          ...booking,
          date: formattedDate,
          time: formattedTime,
        }
      })

      commit('setBookingsUpcoming', pendingBookingsData)

      if (get(data, 'meta.total', 0) > 0) {
        commit('setTotalBookingsUpcoming', get(data, 'meta.total', 0))
      }
    },

    async updateBooking({ commit }, formData) {
      const { data } = await Api.put(`bookings/${formData.id}`, formData)
      commit('setBookingDetails', data.data)
    },

    async updateBookingStatus({ commit }, formData) {
      const { data } = await Api.put(
        `bookings/${formData.id}/update_status`,
        formData
      )
      commit('setSelectedBookingStatus', data.data.status)
    },

    async getPendingBooking({ commit, rootGetters }, params) {
      const queryParams = new URLSearchParams({
        page: params.page || 1,
        sort: params.sort || '',
        include: ['user', 'building', 'bookable.media', 'user.property'],
        limit: params.itemsPerPage || 5,
      })
      // Add building filter if not nil
      let filteredBuilding = rootGetters['filter/buildingId']
      console.log('Filtered Building ID:', filteredBuilding)
      if (filteredBuilding) {
        queryParams.append('filter[building_id]=', filteredBuilding)
      }

      const url = `bookings/pending?${queryParams.toString()}`

      console.log('Constructed URL:', url)

      const { data } = await Api.get(url)

      const pendingBookingsData = data.data.map((booking) => {
        const date = new Date(booking.datetime_start)
        const formattedDate = `${date.getDate()}/${
          date.getMonth() + 1
        }/${date.getFullYear()}`
        const hours = date.getHours() % 12 || 12 // Convert 0 to 12-hour format
        const period = date.getHours() >= 12 ? 'PM' : 'AM'
        const formattedTime = `${hours}:${('0' + date.getMinutes()).slice(
          -2
        )} ${period}`
        return {
          ...booking,
          date: formattedDate,
          time: formattedTime,
        }
      })

      commit('setBookingsPending', pendingBookingsData)

      if (get(data, 'meta.total', 0) > 0) {
        commit('setTotalBookingsPending', get(data, 'meta.total', 0))
      }
    },

    async exportToExcel({ commit, rootGetters }, params) {
      let filteredBuilding = rootGetters['filter/buildingId']
      const paramsWithFilter = {
        include: 'user,building,category,bookable,user.property',
        filter: {
          status: params.filter || '',
          building_id: filteredBuilding || '',
        },
      }

      const file = await Api.get(
        `/bookings/export?include=${paramsWithFilter.include}&filter[status]=${paramsWithFilter.filter.status}&filter[building_id]=${paramsWithFilter.filter.building_id}`,
        {
          responseType: 'blob',
        }
      )
      return file
    },
  },
}
